import { useEffect, useState } from 'react';

import { useAuth } from '../apollo/auth/AuthProvider';
import { useTradespersonDetails } from './useTradespersonDetails';

interface UseTradesperson {
  acceptedTerms: boolean;
  acceptedMarketing: boolean;
  isReady: boolean;
}

export const useTermsAndConditions = (): UseTradesperson => {
  const { isAuthReady, isAuthenticated } = useAuth();
  const { tradesperson, loading } = useTradespersonDetails();

  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [acceptedMarketing, setAcceptedMarketing] = useState(false);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (loading || !isAuthReady) return;

    const consent = tradesperson?.consent ?? [];

    const hasAccepted = !!tradesperson?.hasAcceptedLatestTerms;
    const acceptedConcentExists = consent.some(
      (c) => c?.consentModelClass === 'TradespersonAcceptedTerms',
    );

    const marketingConcentExists = consent.some(
      (c) => c?.consentModelClass === 'TradespersonMarketingConsent',
    );

    setAcceptedTerms(hasAccepted || acceptedConcentExists);
    setAcceptedMarketing(marketingConcentExists);
    setIsReady(true);
  }, [loading, tradesperson, isAuthReady, isAuthenticated]);

  return { acceptedTerms, acceptedMarketing, isReady };
};
