export type TokenObject = Record<
  string,
  string | number | boolean | null | Array<any>
>;

export type User = {
  userId: string;
  context: TokenObject;
  extraClaims?: TokenObject;
};

export type AccessToken = {
  iss?: string;
  sub?: string;
  exp?: string;
  nbf?: string;
  iat?: string;
  jti?: string;
  ctx?: TokenObject;
} & TokenObject;

export enum RequiredAction {
  UpdateSelfBilling = 'update_self_billing',
  UpdateServiceLevelAgreement = 'update_service_level_agreement',
}
