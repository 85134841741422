import { User } from 'configcat-common';
import { useFeatureFlag } from 'configcat-react';

import { useTradespersonDetails } from './useTradespersonDetails';

interface useFlagProps {
  flag: string;
  defaultValue?: any;
  customUserDetails?: { [key: string]: string };
}
export const useFlag = ({
  flag,
  defaultValue,
  customUserDetails = {},
}: useFlagProps) => {
  const { tradesperson, loading } = useTradespersonDetails();
  const user: User = {
    identifier: tradesperson?.id || '',
    email: tradesperson?.emailAddress || '',
    custom: customUserDetails,
  };
  const result = useFeatureFlag(flag, defaultValue, user);
  return { ...result, loading: loading || result.loading };
};
