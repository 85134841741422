import { configure } from 'mobx';

// MOBX
configure({
  useProxies: 'always',

  //UNCOMMENT IF NEEDED

  // enforceActions: 'always',
  // computedRequiresReaction: true,
  // reactionRequiresObservable: true,
  // observableRequiresReaction: true,
  // disableErrorBoundaries: true,
});
