import 'mobx.config';

import Head from 'next/head';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { DEFAULT_LANDING_PAGE } from 'src/apollo/auth/hooks/useAuthFlow';

import KantanLogo from '../../public/svg/KantanLogoSmall.svg';
import { DefaultLayout } from '../layouts/DefaultLayout';
import { NextPageWithLayout } from '../utils/types';

const Home: NextPageWithLayout = () => {
  const router = useRouter();

  useEffect(() => {
    router.replace(DEFAULT_LANDING_PAGE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Head>
        <title>Welcome Kantan</title>
        <meta name="description" content="Kantan Web App" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <main className="flex flex-col items-center justify-center min-h-screen min-w-full bg-blue-500">
        <KantanLogo
          width={120}
          height={120}
          viewBox="0 0 33 34"
          data-testid="kantan-logo"
        />
      </main>
    </>
  );
};

Home.getLayout = (page: any) => <DefaultLayout>{page}</DefaultLayout>;

export default Home;
