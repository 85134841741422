import { useMemo } from 'react';

import { useFlag } from 'src/hooks/useFlag';
import { useTermsAndConditions } from 'src/hooks/useTermsAndConditions';

import { useAuth } from '../AuthProvider';
import { extractRequiredActionsFromUser } from './requiredActions';

export const DEFAULT_LANDING_PAGE = '/calendar';
export const TERMS_AND_CONDITIONS_PAGE = '/terms-and-conditions/accept';
export const LOGIN_PAGE = '/login';
export const SELF_BILLING_PAGE = '/self-billing-agreement/accept';
export const SERVICE_LEVEL_AGREEMENT_PAGE = '/service-level-agreement/accept';
export const SIGNED_SERVICE_LEVEL_AGREEMENT_PAGE =
  '/service-level-agreement/signed';

type UseAuthFlowReturn =
  | 'loading'
  | 'redirectToLanding'
  | 'redirectToLogin'
  | 'redirectToTerms'
  | 'redirectToDeeplink'
  | 'redirectToSelfBilling'
  | 'redirectToServiceLevelAgreement';

/**
 * Determines the next step in the authentication flow
 */
export function useAuthFlow(): UseAuthFlowReturn {
  const networkPortalParityRequiredActionsEnabled = useFlag({
    flag: 'networkPortalParityRequiredActions',
    defaultValue: false,
  });

  const { acceptedTerms, isReady: termsAndConditionsReady } =
    useTermsAndConditions();
  const { isAuthReady, isAuthenticated, user } = useAuth();

  const { shouldRedirectToSelfBilling, shouldRedirectToServiceLevelAgreement } =
    useMemo(() => {
      return extractRequiredActionsFromUser(user);
    }, [user]);

  if (
    !isAuthReady ||
    !termsAndConditionsReady ||
    networkPortalParityRequiredActionsEnabled.loading
  ) {
    return 'loading';
  }

  if (!isAuthenticated) {
    return 'redirectToLogin';
  }

  // eslint-disable-next-line no-restricted-syntax
  const deferredDeeplink = localStorage.getItem('deferredDeeplink');

  // TODO: not entirely sure if this should rank higher than the other conditions
  // but this was the original order prior to the refactor.
  if (!!deferredDeeplink) {
    return 'redirectToDeeplink';
  }

  if (!acceptedTerms) {
    return 'redirectToTerms';
  }

  if (networkPortalParityRequiredActionsEnabled.value) {
    if (shouldRedirectToServiceLevelAgreement) {
      return 'redirectToServiceLevelAgreement';
    }

    if (shouldRedirectToSelfBilling) {
      return 'redirectToSelfBilling';
    }
  }

  return 'redirectToLanding';
}
