import { captureException } from '@sentry/nextjs';

import { RequiredAction, User } from '../types';

interface UserContext {
  partner_actions_required?: Record<string, Array<RequiredAction>>;
}

interface RequiredActions {
  shouldRedirectToSelfBilling: boolean;
  shouldRedirectToServiceLevelAgreement: boolean;
}

export function extractRequiredActionsFromUser(
  user: User | undefined,
): RequiredActions {
  let shouldRedirectToSelfBilling = false;
  let shouldRedirectToServiceLevelAgreement = false;

  try {
    const context = user?.context as unknown as UserContext | undefined;
    if (context?.partner_actions_required) {
      const partnerActions = Object.values(context.partner_actions_required);

      for (const requiredAction of partnerActions) {
        if (
          requiredAction.includes(RequiredAction.UpdateServiceLevelAgreement)
        ) {
          shouldRedirectToServiceLevelAgreement = true;
        }
        if (requiredAction.includes(RequiredAction.UpdateSelfBilling)) {
          shouldRedirectToSelfBilling = true;
        }
      }
    }
  } catch (e) {
    captureException(e);
  }

  return {
    shouldRedirectToSelfBilling,
    shouldRedirectToServiceLevelAgreement,
  };
}
