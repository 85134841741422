/* eslint-disable no-restricted-syntax */
import { usePartners } from 'kantan-utils';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { PropsWithChildren, useEffect } from 'react';

import {
  DEFAULT_LANDING_PAGE,
  SELF_BILLING_PAGE,
  SERVICE_LEVEL_AGREEMENT_PAGE,
  TERMS_AND_CONDITIONS_PAGE,
  useAuthFlow,
} from 'src/apollo/auth/hooks/useAuthFlow';
import { handleWebRedirects } from 'src/screens/magicLink/useHandleMagicLinkRedirect';
import { mapPartner, mapPartnerToKey } from 'src/utils/partners';

interface DefaultLayoutProps {}

export const DefaultLayout = ({
  children,
}: PropsWithChildren<DefaultLayoutProps>) => {
  const authFlowStep = useAuthFlow();
  const router = useRouter();
  const { partners } = usePartners();

  useEffect(() => {
    switch (authFlowStep) {
      case 'redirectToLanding':
        void router.replace(DEFAULT_LANDING_PAGE);
        break;
      case 'redirectToDeeplink':
        const deferredDeeplink = localStorage.getItem('deferredDeeplink');
        const queryParams = JSON.parse(deferredDeeplink as string);
        handleWebRedirects(
          queryParams,
          mapPartner(
            mapPartnerToKey(partners, queryParams.partner_id as string),
          ),
          router,
        );
        localStorage.removeItem('deferredDeeplink');
      case 'redirectToTerms':
        void router.replace(TERMS_AND_CONDITIONS_PAGE);
        break;
      case 'redirectToSelfBilling':
        void router.replace(SELF_BILLING_PAGE);
        break;
      case 'redirectToServiceLevelAgreement':
        void router.replace(SERVICE_LEVEL_AGREEMENT_PAGE);
        break;

      // these were never handled before the refactor, so not changing this for now
      case 'loading':
      case 'redirectToLogin':
        break;

      default:
        const _exhaustiveCheck: never = authFlowStep;
        break;
    }
  }, [authFlowStep, partners, router]);

  return (
    <>
      <Head>
        <title>Kantan</title>
        <meta name="description" content="Kantan Web App" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      {children}
    </>
  );
};
