import { usePartners } from 'kantan-utils';
import { NextRouter, useRouter } from 'next/router';
import { ParsedUrlQuery } from 'querystring';
import { useCallback, useEffect, useMemo } from 'react';

import { useAuth } from 'src/apollo/auth/AuthProvider';
import { DEFAULT_LANDING_PAGE } from 'src/apollo/auth/hooks/useAuthFlow';
import { useTradespersonAccountDeletionProgress } from 'src/apollo/queries';
import { mapPartner, mapPartnerToKey } from 'src/utils/partners';

export const IOS_APP_STORE_LINK =
  'https://apps.apple.com/gb/app/kantan/id1486148789';
export const ANDROID_APP_STORE_LINK =
  'https://play.google.com/store/apps/details?id=tech.kantan.kantanApp.production&pli=1';

export const useHandleMagicLinkRedirect = (isMobile: boolean) => {
  const router = useRouter();
  const { isAuthenticated, isAuthReady } = useAuth();
  const { partners } = usePartners();
  const { data: tradespersonDeletionStatusData } =
    useTradespersonAccountDeletionProgress({
      skip: !isAuthReady || !isAuthenticated,
    });

  const alreadyAuthenticated = useMemo(
    () =>
      isAuthReady &&
      isAuthenticated &&
      !tradespersonDeletionStatusData?.tradesperson?.isDeletionInProgress,
    [
      isAuthenticated,
      isAuthReady,
      tradespersonDeletionStatusData?.tradesperson?.isDeletionInProgress,
    ],
  );

  const { query, isReady } = router;

  const triggerLogic = useCallback(() => {
    if (!isReady) return;
    if (isMobile) {
      // If the user is on a mobile device, redirect to the app
      handleAppRedirects(query);
    } else if (alreadyAuthenticated && !!partners) {
      // If the user is on a desktop device and logged in, redirect to the relevant page
      // TODO: update to support companyId alongside comms updates
      const partnerKey = mapPartner(
        mapPartnerToKey(partners, query.partner_id as string),
      );

      handleWebRedirects(query, partnerKey, router);
    } else {
      if (!!query.path && query.path !== 'auth') {
        // TP is not logged in, redirect to auth and save deeplink query for later
        // eslint-disable-next-line no-restricted-syntax
        localStorage.setItem('deferredDeeplink', JSON.stringify(query));
      }
      void router.push({
        pathname: '/auth',
        query: {
          token: query.token,
        },
      });
    }
  }, [alreadyAuthenticated, isMobile, partners, query, router, isReady]);

  useEffect(() => {
    triggerLogic();
  }, [triggerLogic]);

  return triggerLogic;
};

export function generateQueryParamsString(queryParams: ParsedUrlQuery) {
  if (!queryParams) return '';

  const encodedParams = Object.entries(queryParams)
    .filter(([_key, value]) => value !== undefined)
    .map(([key, value]) => `${key}=${encodeURIComponent(value as string)}`)
    .join('&');

  return encodedParams ? `?${encodedParams}` : '';
}

export function returnAppStoreLink() {
  if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
    // Redirect to the App Store on iOS devices
    return IOS_APP_STORE_LINK;
  } else if (/Android/.test(navigator.userAgent)) {
    // Redirect to the Play Store on Android devices
    return ANDROID_APP_STORE_LINK;
  }

  return;
}

export function handleAppRedirects(queryParams: ParsedUrlQuery) {
  const {
    path,
    job_id: jobId,
    partner_id: _partnerId,
    ...restOfQuery
  } = queryParams;
  const queryParamsString = generateQueryParamsString(restOfQuery);

  switch (path) {
    case 'job':
      window.open(`kantan://job/${jobId}/${queryParamsString}`, '_self');
      break;
    default:
      window.open(`kantan://${path}${queryParamsString}`, '_self');
      break;
  }

  // If the app does not exist, redirect to the relevant app store
  setTimeout(() => {
    window.open(returnAppStoreLink(), '_self');
  }, 3000);
}
//TODO: determine if refactoring is necessary
export function handleWebRedirects(
  queryParams: ParsedUrlQuery,
  partnerKey: string,
  router: NextRouter,
) {
  const {
    path,
    job_id: jobId,
    partner_id: partnerId,
    ...restOfQuery
  } = queryParams;
  const queryParamsString = generateQueryParamsString(restOfQuery);

  switch (path) {
    case 'job':
      void router.push(`/jobs/${partnerKey}/${jobId}${queryParamsString}`);
      return;
    case 'auth':
    case 'calendar':
      void router.push(`/${path}${queryParamsString}`);
      return;
    default:
      // Unsupported route, redirect to landing page
      void router.push(DEFAULT_LANDING_PAGE);
      return;
  }
}
